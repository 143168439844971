<template>
  <div>
    <nav class="navbar is-primary">
      <div class="container">
        <div class="navbar-brand"></div>

        <div class="navbar-menu">
          <div class="navbar-start">
            <div class="navbar-item">Vizieroost | gemeente rapportages</div>
            <router-link exact class="navbar-item" :to="{ name: 'app' }"
              >Dashboard</router-link
            >
            <router-link exact class="navbar-item" :to="{ name: 'uploads' }"
              >Data dumps</router-link
            >
            <router-link class="navbar-item" :to="{ name: 'rapportages-index' }"
              >Rapportages</router-link
            >
          </div>
          <div class="navbar-end">
            <router-link class="navbar-item" :to="{ name: 'formats-index' }"
              >Formats</router-link
            >
            <router-link
              v-if="user.role === 'admin'"
              class="navbar-item"
              :to="{ name: 'gebruikers-index' }"
              >Gebruikers</router-link
            >
            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link">
                {{ user.name }}
              </a>
              <div class="navbar-dropdown">
                <router-link class="navbar-item" :to="{ name: 'instellingen' }"
                  >Instellingen</router-link
                >
                <hr class="navbar-divider" />
                <a class="navbar-item" @click="logout()"> Uitloggen </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
  },
  methods: {
    logout() {
      this.$router.push({ name: "inloggen" });
      this.$store.dispatch("auth/logout").then(() => {
        this.$store.dispatch("createFlashBanner", {
          message: "U bent succesvol uitgelogd!",
          timeout: 5,
        });
      });
    },
  },
  mounted() {},
};
</script>
