<template>
  <div>
    <div class="card">
      <header class="card-header pointer" @click="toggleOpen(category)">
        <p class="card-header-title">{{ hoofdletter(category) }}</p>
      </header>
      <span v-if="open === category">
        <div class="card-content">
          <span v-if="showVariables">
            <strong>Usable variables:</strong> <br />
            <div class="columns">
              <div class="column">
                Firstname (only usable with orders)<br />
                Surname (only usable with orders)<br />
                Order number (only usable with orders)<br />
                <br />
                Customer firstname (only usable with invoices) <br />
                Customer surname (only usable with invoices) <br />
                Invoice number (only usable with invoices) <br />
                Invoice total (only usable with invoices) <br />
                <br />
                Contact email link (shows: your email) <br />
                Contact form link (shows: contact form)<br />
              </div>
              <div class="column">
                @@@@name@@@@ <br />
                @@@@surname@@@@ <br />
                @@@@order_number@@@@ <br />
                <br />
                @@@@customer_name@@@@ <br />
                @@@@customer_surname@@@@ <br />
                @@@@invoice_number@@@@ <br />
                @@@@invoice_total@@@@ <br />
                <br />
                @@@@contact_email@@@@ <br />
                @@@@contact_form@@@@
              </div>
            </div>
            <br />
          </span>
          <div class="content">
            <span v-if="computedSettings">
              <span v-for="variable in computedSettings.length" :key="variable">
                <b-switch
                  class="customInput"
                  v-if="computedSettings[variable - 1].type === 'switch'"
                  v-model="form[computedSettings[variable - 1].key]"
                >
                  {{ computedSettings[variable - 1].name }}
                </b-switch>

                <br v-if="computedSettings[variable - 1].type === 'switch'" />

                <formEditor
                  :value="form[computedSettings[variable - 1].key]"
                  class="customInput"
                  :model="form"
                  :title="computedSettings[variable - 1].name"
                  :modelKey="computedSettings[variable - 1].key"
                  v-if="computedSettings[variable - 1].type === 'editor'"
                />

                <formInput
                  v-if="computedSettings[variable - 1].type === 'input'"
                  class="customInput"
                  :title="computedSettings[variable - 1].name"
                  :model="form"
                  :modelKey="computedSettings[variable - 1].key"
                  label="on-border"
                  :placeholder="computedSettings[variable - 1].name"
                />

                <formCheckbox
                  v-if="computedSettings[variable - 1].type === 'checkbox'"
                  class="customInput"
                  :title="computedSettings[variable - 1].name"
                  :model="form"
                  :modelKey="computedSettings[variable - 1].key"
                  :labelHidden="true"
                  :placeholder="computedSettings[variable - 1].name"
                />

                <FormSelect
                  v-if="computedSettings[variable - 1].type === 'select'"
                  class="customInput"
                  :selectOptions="computedSettings[variable - 1].options"
                  :title="computedSettings[variable - 1].name"
                  :model="form"
                  label="on-border"
                  :modelKey="computedSettings[variable - 1].key"
                  :placeholder="computedSettings[variable - 1].name"
                />

                <br />
              </span>
            </span>
          </div>
        </div>
        <footer class="card-footer">
          <span class="card-footer-item">
            <button
              :class="{
                'is-loading': ajaxloading.save_variables,
              }"
              class="button is-primary is-rounded"
              @click="saveVariables()"
            >
              Opslaan
            </button></span
          >
        </footer>
      </span>
    </div>
  </div>
</template>

<script>
import FormSelect from "../form/formSelect.vue";
export default {
  components: { FormSelect },
  props: {
    category: {
      required: true,
      type: String,
    },
    open: {
      required: true,
      type: String,
    },
    settings: {
      required: true,
      type: Array,
    },
    form: {
      required: true,
      type: Object,
    },
    showVariables: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {
    computedSettings() {
      if (this.settings.length) {
        return this.settings.filter((a) => a.category === this.category);
      } else {
        return [];
      }
    },
  },
  methods: {
    saveVariables() {
      this.$emit("save", this.category);
    },
    toggleOpen() {
      this.$emit("toggle", this.category);
    },
  },
  mounted() {},
};
</script>
