<template>
  <div>
    <div class="title is-4">Rapportage formats</div>
    <button
      class="button is-fullwidth is-primary"
      @click="$router.push({ name: 'create-format' })"
    >
      Creëer nieuw format
    </button>
    <br />
    <formatsTable :formats="formats" />
  </div>
</template>

<script>
import formatsTable from "./FormatsTable.vue";

export default {
  components: {
    formatsTable,
  },
  props: [],
  data() {
    return {};
  },
  computed: {
    formats() {
      return this.$store.getters["formats/formats"];
    },
  },
  methods: {},
  mounted() {},
};
</script>
