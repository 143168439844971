<template>
  <div>
    <b-table
      :data="dumps"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
    >
      <b-table-column label="#" v-slot="props">
        {{ props.row.id }}
      </b-table-column>

      <b-table-column label="Interne naam" v-slot="props">
        <b-tooltip label="Naam veranderen">
          <a
            @click="
              $router.push({
                name: 'update-data-dump',
                params: { datadump: props.row.id },
              })
            "
            >{{ props.row.naam }}</a
          >
        </b-tooltip>
      </b-table-column>

      <b-table-column label="Bestandsnaam" v-slot="props">
        <b-tooltip label="Bestand downloaden">
          <a @click="downloadFile(props.row)">{{ props.row.bestands_naam }}</a>
        </b-tooltip>
      </b-table-column>

      <b-table-column label="Geupload" v-slot="props">
        {{ formatDatabaseDate(props.row.created_at) }}
        {{ formatDatabaseTime(props.row.created_at) }}
      </b-table-column>

      <b-table-column label="Status" v-slot="props">
        {{ props.row.status }}
      </b-table-column>

      <b-table-column label="Acties" v-slot="props">
        <b-tooltip label="Data dump verwijderen">
          <button
            class="button is-danger is-small"
            @click="deleteDump(props.row)"
          >
            delete
          </button>
        </b-tooltip>
        {{ props.naam }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    dumps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    deleteDump(dump) {
      this.$buefy.dialog.confirm({
        title: "Deleting datadump",
        message:
          "Weet u zeker dat u deze datadump wilt verwijderen? <br> Deze actie kan niet ongedaan gemaakt worden.",
        confirmText: "Delete datadump",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store.dispatch("uploads/deleteDatadump", dump).then(() => {
            this.$store.dispatch("createFlashBanner", "Datadump deleted!");
          }),
      });
    },
    downloadFile(dump) {
      this.$store.dispatch("uploads/downloadDatadump", dump);
    },
  },
  mounted() {},
};
</script>
