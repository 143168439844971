<template>
  <div>
    <section class="hero is-login is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-4">
              <div class="container box" v-show="showForm">
                <!-- email form -->
                <form @submit.prevent="loginButtonClicked()">
                  <span
                    class="has-text-centered title is-3"
                    style="color: black"
                    >Inloggen</span
                  >
                  <br />
                  <br />

                  <b-field
                    label="Email"
                    :type="{ 'is-danger': validation.email }"
                    :message="{ [validation.email]: validation.email }"
                  >
                    <b-input
                      v-model="form.email"
                      rounded
                      placeholder="Emailadres"
                    ></b-input>
                  </b-field>

                  <b-field
                    label="Password"
                    :type="{ 'is-danger': validation.password }"
                    :message="{ [validation.password]: validation.password }"
                  >
                    <b-input
                      type="password"
                      rounded
                      placeholder="Password"
                      password-reveal
                      v-model="form.password"
                    >
                    </b-input>
                  </b-field>

                  <b-button
                    native-type="submit"
                    type="is-primary is-rounded"
                    :loading="ajaxloading.inloggen"
                    >Inloggen</b-button
                  >
                  <p class="help">
                    <router-link tag="a" :to="{ name: 'wachtwoord-resetten' }"
                      >Wachtwoord vergeten?</router-link
                    >
                  </p>
                </form>
              </div>

              <div class="container box" v-show="!showForm">
                <!-- email form -->
                <form @submit.prevent="authenticateCode()">
                  <span
                    class="has-text-centered title is-3"
                    style="color: black"
                    >Bevestig uw inlog</span
                  >
                  <br />
                  <br />
                  <span class="has-text-centered subitle is-5"
                    >Bevestig uw identiteit met de code die zojuist naar uw
                    emailadres is verzonden.<br />
                    <br />
                    <a @click="resend2faEmail()"
                      >Geen email ontvangen? Klik hier om een nieuwe email te
                      versturen</a
                    ></span
                  >
                  <br />
                  <br />

                  <b-field
                    label="Code"
                    :type="{ 'is-danger': validation.code }"
                    :message="{ [validation.code]: validation.code }"
                  >
                    <b-input
                      v-model="code"
                      rounded
                      placeholder="Code"
                    ></b-input>
                  </b-field>

                  <b-button
                    native-type="submit"
                    :loading="ajaxloading.validatingcode"
                    type="is-primary is-rounded"
                    >Inloggen</b-button
                  >
                  <p class="help">
                    <a @click="backToLoginForm()">Terug naar inloggen</a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {},
      showForm: true,
      code: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    loginToken() {
      return this.$store.getters["auth/loginToken"];
    },
  },
  methods: {
    loginButtonClicked() {
      this.$store
        .dispatch("auth/login", this.form)
        .then((response) => {
          if (response.data.token) {
            this.$router.replace({ name: "app" });
          } else {
            this.$store.dispatch("createFlashBanner", {
              message: "Email verzonden",
              timeout: 5,
            });
            this.showForm = false;
            this.form = {};
          }
        })
        .catch((error) => {
          if (error.response.status === 450) {
            this.$store.dispatch("createFlashBanner", {
              message:
                "Uw account is vergrendeld. Neem contact op met uw systeembeheerder.",
              timeout: 60,
            });
          }
        });
    },
    authenticateCode() {
      this.$store
        .dispatch("auth/validatingcode", {
          code: this.code,
          user: this.$store.getters["auth/user"],
          loginToken: this.loginToken,
        })
        .then((response) => {
          if (response.data === "onjuiste code.") {
            this.code = null;
            this.$store.dispatch("createFlashBanner", {
              message: "Onjuiste code, probeer het opnieuw",
              timeout: 5,
            });
          } else {
            this.$router.replace({ name: "app" });
            this.$store.dispatch("createFlashBanner", {
              message: "U bent succesvol ingelogd!",
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 451) {
            this.$store.dispatch("createFlashBanner", {
              message: "Inlogpoging verlopen. Probeer het opnieuw.",
              timeout: 5,
            });
            this.backToLoginForm();
          }
        });
    },
    backToLoginForm() {
      this.showForm = true;
      this.code = null;
      this.$store.dispatch("auth/clearLoginData");
    },
    resend2faEmail() {
      if (this.user.name) {
        this.$store
          .dispatch("auth/resend2faEmail")
          .then(() => {
            this.$store.dispatch("createFlashBanner", {
              message: "Email opnieuw verzonden",
              timeout: 5,
            });
          })
          .catch((error) => {
            if (error.response.status === 451) {
              this.$store.dispatch("createFlashBanner", {
                message:
                  "Laatste email is minder dan 2 minuten geleden verzonden. Controleer uw inbox",
                timeout: 5,
              });
            }
          });
      } else {
        this.backToLoginForm();
      }
    },
  },
  mounted() {
    this.$store
      .dispatch("auth/getLoggedInUserWithoutError")
      .then((response) => {
        if (
          this.$router.currentRoute.name === "inloggen" &&
          response.data !== "niet ingelogd"
        ) {
          this.$router.replace({ name: "app" });
        }
      });
  },
};
</script>

<style scoped></style>
