<template>
  <div class="container">
    <br />
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
    this.$store.dispatch("formats/getFormats");
  },
};
</script>
