<template>
  <div class="mainContainer">
    <systemnavbar />
    <router-view />
  </div>
</template>

<script>
import systemnavbar from "@/components/headers/navbar.vue";

export default {
  components: {
    systemnavbar,
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
    this.$store.dispatch("auth/getLoggedInUser");
    this.$store.dispatch("auth/getSettings");
  },
};
</script>
