var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header pointer",on:{"click":function($event){return _vm.toggleOpen(_vm.category.naam)}}},[_c('p',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.hoofdletter(_vm.category.naam)))])]),(_vm.open === _vm.category.naam)?_c('span',[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},_vm._l((Object.keys(_vm.computedSettings)),function(sub_category){return _c('span',{key:sub_category.name},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.computedSettings[sub_category].name)+" ")]),_c('div',{staticClass:"column"},[_c('formInput',{staticClass:"customInput",attrs:{"title":"Cell: inwoner in gemeente, voorval in gemeente","model":_vm.form,"modelKey":'rapportage_categorieen|' +
                    _vm.category.key +
                    '|' +
                    sub_category +
                    '|inwoner_en_voorval_in_gemeente',"label":"on-border","placeholder":"Bijvoorbeeld A4"}}),_c('br'),_c('formInput',{staticClass:"customInput",attrs:{"title":"Cell: Inwoner in gemeente, voorval buiten gemeente","model":_vm.form,"modelKey":'rapportage_categorieen|' +
                    _vm.category.key +
                    '|' +
                    sub_category +
                    '|melder_in_gemeente',"label":"on-border","placeholder":"Bijvoorbeeld A4"}}),_c('br'),_c('formInput',{staticClass:"customInput",attrs:{"title":"Cell: totaal inwoners","model":_vm.form,"modelKey":'rapportage_categorieen|' +
                    _vm.category.key +
                    '|' +
                    sub_category +
                    '|totaal_inwoners',"label":"on-border","placeholder":"Bijvoorbeeld A4"}}),_c('br'),_c('formInput',{staticClass:"customInput",attrs:{"title":"Cell: Inwoner buiten gemeente, voorval in gemeente","model":_vm.form,"modelKey":'rapportage_categorieen|' +
                    _vm.category.key +
                    '|' +
                    sub_category +
                    '|voorval_in_gemeente',"label":"on-border","placeholder":"Bijvoorbeeld A4"}}),_c('br'),_c('formInput',{staticClass:"customInput",attrs:{"title":"Cell: totaal in gemeente","model":_vm.form,"modelKey":'rapportage_categorieen|' +
                    _vm.category.key +
                    '|' +
                    sub_category +
                    '|totaal_in_gemeente',"label":"on-border","placeholder":"Bijvoorbeeld A4"}})],1)]),_c('hr')])}),0)]),_c('footer',{staticClass:"card-footer"},[_c('span',{staticClass:"card-footer-item"},[_c('button',{staticClass:"button is-primary is-rounded",class:{
              'is-loading': _vm.ajaxloading.save_variables,
            },on:{"click":function($event){return _vm.saveVariables()}}},[_vm._v(" Opslaan ")])])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }