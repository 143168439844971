<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}
</style>

<template>
  <div class="content">
    <div class="users-title">Gebruiker toevoegen</div>
    <form @submit.prevent="gebruikerToevoegen()">
      <br />
      <br />

      <b-field
        label="Naam"
        :type="{ 'is-danger': validation.naam }"
        :message="{ [validation.naam]: validation.naam }"
      >
        <b-input type="input" rounded placeholder="Naam" v-model="form.naam">
        </b-input>
      </b-field>

      <b-field
        label="Achternaam"
        :type="{ 'is-danger': validation.achternaam }"
        :message="{ [validation.achternaam]: validation.achternaam }"
      >
        <b-input
          type="input"
          rounded
          placeholder="Achternaam"
          v-model="form.achternaam"
        >
        </b-input>
      </b-field>

      <b-field
        label="Email"
        :type="{ 'is-danger': validation.email }"
        :message="{ [validation.email]: validation.email }"
      >
        <b-input
          v-model="form.email"
          rounded
          placeholder="Emailadres"
        ></b-input>
      </b-field>

      <b-field
        label="Rol"
        :type="{ 'is-danger': validation.rol }"
        :message="{ [validation.rol]: validation.rol }"
      >
        <b-select
          v-if="settings.gebruikers"
          placeholder="Selecteer een rol"
          v-model="form.rol"
          rounded
          expanded
        >
          <option
            v-for="rol of settings.gebruikers.rollen"
            :key="rol"
            :value="rol"
          >
            {{ hoofdletter(rol) }}
          </option>
        </b-select>
      </b-field>

      <br />

      <div class="field">
        <b-checkbox v-model="form.uitnodigen">
          Uitnodiging versturen naar gebruiker
        </b-checkbox>
      </div>

      <br />

      <b-button
        native-type="submit"
        :loading="ajaxloading.gebruikertoevoegen"
        type="is-primary is-rounded"
        >Gebruiker aanmaken</b-button
      >&nbsp;

      <b-button type="is-rounded" @click="$router.go(-1)">Annuleren</b-button>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      selected: null,
      form: {
        uitnodigen: true,
        arnhem: false,
        enschede: false,
        overige_gemeente: false,
        sendnotification_enschede: false,
        sendnotification_overige_gemeente: false,
        sendnotificationarnhem: false,
        rapportages_arnhem: false,
        rapportages_enschede: false,
        rapportages_overige_gemeente: false,
      },
    };
  },
  computed: {
    gebruikers() {
      return this.$store.getters["gebruikers/gebruikers"];
    },
    settings() {
      return this.$store.getters["auth/settings"];
    },
  },
  methods: {
    gebruikerToevoegen() {
      this.$store
        .dispatch("gebruikers/gebruikerToevoegen", this.form)
        .then(() => {
          this.$store.dispatch("createFlashBanner", {
            message: "Gebruiker aangemaakt.",
            timeout: 3,
          });
          this.$router.push({ name: "gebruikers-index" });
        });
    },
  },
  mounted() {},
};
</script>
