<template>
  <div>
    <div class="title">Update format instellingen</div>
    <!-- algemeen -->

    <strong>Bruikbare variabelen:</strong> <br />
    <div class="columns">
      <div class="column">
        Gemeente<br />
        Datum van vandaag (DD-MM-JJJJ)<br />
        Jaar van rapportage (JJJJ)<br />
        Jaar van rapportage kort (JJ)<br />
      </div>
      <div class="column"></div>
      <div class="column">
        @@@@gemeente@@@@ <br />
        @@@@huidige_datum@@@@ <br />
        @@@@rapportage_jaar@@@@ <br />
        @@@@rapportage_jaar_kort@@@@ <br />
      </div>
      <div class="column"></div>
    </div>

    <formatGeneral
      :form="form"
      category="algemeen"
      :settings="settings"
      :open="open"
      v-on:toggle="toggleCategory"
      v-on:save="saveSettings"
    />

    <br />

    <span v-if="settings && settings.rapportage_categorieen">
      <span
        v-for="category in settings.rapportage_categorieen"
        :key="category.naam"
      >
        <formatCategory
          :form="form"
          :category="category"
          :open="open"
          :settings="settings"
          v-on:toggle="toggleCategory"
          v-on:save="saveCategory"
        />
        <br />
      </span>
    </span>

    <span v-if="settings && settings.klacht_behandelingen">
      <span
        v-for="category in settings.klacht_behandelingen"
        :key="category.naam"
      >
        <formatComplaint
          :form="form"
          :category="category"
          :open="open"
          :settings="settings"
          v-on:toggle="toggleCategory"
          v-on:save="saveCategory"
        />
        <br />
      </span>
    </span>

    <span v-if="settings && settings.gemeenten">
      <formatGemeenten
        :form="form"
        :open="open"
        :settings="settings"
        v-on:toggle="toggleCategory"
        v-on:save="saveGemeenten"
      />
    </span>

    <!-- <formatCategory
      :form="form"
      category="discriminatiegrond"
      :settings="settings"
      :open="open"
      v-on:toggle="toggleCategory"
      v-on:save="saveSettings"
    />
    <br />

    <formatCategory
      :form="form"
      category="maatschappelijk terrein"
      :settings="settings"
      :open="open"
      v-on:toggle="toggleCategory"
      v-on:save="saveSettings"
    />
    <br />

    <formatCategory
      :form="form"
      category="wijze van discriminatie"
      :settings="settings"
      :open="open"
      v-on:toggle="toggleCategory"
      v-on:save="saveSettings"
    />

    <br />
    <formatCategory
      :form="form"
      category="klachtbehandeling"
      :settings="settings"
      :open="open"
      v-on:toggle="toggleCategory"
      v-on:save="saveSettings"
    /> -->

    <br />
    <button
      :class="{ 'is-loading': ajaxloading.uploading_data_dump }"
      class="button is-primary is-fullwidth"
      @click="$router.go(-1)"
    >
      Terugkeren
    </button>
  </div>
</template>

<script>
import formatCategory from "./FormatCategory.vue";
import formatGeneral from "./FormatGeneral.vue";
import formatComplaint from "./FormatComplaint.vue";
import formatGemeenten from "./FormatGemeenten.vue";
import Vue from "vue";

export default {
  components: {
    formatComplaint,
    formatCategory,
    formatGeneral,
    formatGemeenten,
  },
  props: [],
  data() {
    return {
      form: {},
      open: "",
    };
  },
  computed: {
    formats() {
      return this.$store.getters["formats/formats"];
    },
    format() {
      const format = this.formats.find(
        (a) => a.id == this.$router.currentRoute.params.format
      );

      if (format) {
        return format;
      }

      return {};
    },
    settings() {
      return this.$store.getters["auth/settings"];
    },
    algemeneCategorien() {
      return this.settings.datadump_waarden.filter(
        (a) => a.category === "algemeen"
      );
    },
    tekstCategorien() {
      return this.settings.datadump_waarden.filter(
        (a) => a.category === "teksten"
      );
    },
  },
  methods: {
    toggleCategory(category) {
      if (this.open === category) {
        return (this.open = "");
      }

      this.open = category;
    },
    setVariables() {
      const array = this.format.settings;

      array.forEach((a) => {
        // if (a.type === "boolean") {
        //   if (a.value == 1) {
        //     Vue.set(this.form, a.key, true);
        //   }

        //   if (a.value == 0) {
        //     Vue.set(this.form, a.key, false);
        //   }
        // } else {
        Vue.set(this.form, a.key, a.value);
        // }
      });
    },
    saveSettings(category) {
      this.form.format = this.format;
      this.form.category = category;
      this.$store
        .dispatch("formats/saveFormatGeneralSettings", this.form)
        .then(() => {
          this.open = "";
          this.$store.dispatch("createFlashBanner", "Wijzigingen opgeslagen.");
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.$store.dispatch("createFlashBanner", {
              type: "is-danger",
              message: error.response.data,
              timeout: 10,
            });
          }
        });
    },
    saveCategory(category) {
      this.form.format = this.format;
      this.form.category = category;
      this.$store
        .dispatch("formats/saveFormatCategorySettings", this.form)
        .then(() => {
          this.open = "";
          this.$store.dispatch("createFlashBanner", "Wijzigingen opgeslagen.");
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.$store.dispatch("createFlashBanner", {
              type: "is-danger",
              message: error.response.data,
              timeout: 10,
            });
          }
        });
    },
    saveGemeenten(gemeenten) {
      const payload = {
        format: this.format,
        gemeenten: gemeenten,
      };
      this.$store
        .dispatch("formats/saveFormatGemeenten", payload)
        .then(() => {
          this.open = "";
          this.$store.dispatch("createFlashBanner", "Wijzigingen opgeslagen.");
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.$store.dispatch("createFlashBanner", {
              type: "is-danger",
              message: error.response.data,
              timeout: 10,
            });
          }
        });
    },
  },
  mounted() {
    if (this.format && this.format.settings) {
      this.setVariables();
    } else {
      this.$store.dispatch("formats/getFormats").then(() => {
        this.setVariables();
      });
    }
  },
};
</script>
