<template>
  <div>
    <b-field
      :label="hoofdletter(computedTitle)"
      :type="{ 'is-danger': validation[modelKey] }"
      :message="validation[modelKey]"
    >
      <b-taginput
        v-model="model[modelKey]"
        @input="clearValidationError()"
        ellipsis
        icon="tag"
        placeholder="Add a tag"
        aria-close-label="Delete this tag"
      >
      </b-taginput>
    </b-field>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
    },
    model: {
      required: true,
      type: Object,
    },
    modelKey: {
      required: true,
      type: String,
    },
    placeholder: {
      type: String,
    },
    size: {
      default: "is-normal",
    },
  },
  data() {
    return {};
  },
  computed: {
    computedTitle() {
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    },
  },
  methods: {
    clearValidationError() {
      this.$store.dispatch("clearValidationError", this.modelKey);
    },
  },
  mounted() {},
};
</script>
