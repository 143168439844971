<style scoped>
.column {
  margin-bottom: 0px !important;
}
.columns {
  margin-bottom: 0px !important;
}
.customInput {
  margin-bottom: 0px !important;
}

.rotateText {
  transform: rotate(-90deg); /* Equal to rotateZ(45deg) */
}
</style>

<template>
  <div>
    <div class="card">
      <header class="card-header pointer" @click="toggleOpen(category.naam)">
        <p class="card-header-title">{{ hoofdletter(category.naam) }}</p>
      </header>
      <span v-if="open === category.naam">
        <div class="card-content">
          <div class="content">
            <span
              v-for="sub_category in Object.keys(computedSettings)"
              :key="sub_category.name"
            >
              <div class="columns">
                <div class="column">
                  {{ computedSettings[sub_category].name }}
                </div>
                <div class="column">
                  <formInput
                    class="customInput"
                    title="Cell: inwoner in gemeente, voorval in gemeente"
                    :model="form"
                    :modelKey="
                      'rapportage_categorieen|' +
                      category.key +
                      '|' +
                      sub_category +
                      '|inwoner_en_voorval_in_gemeente'
                    "
                    label="on-border"
                    placeholder="Bijvoorbeeld A4"
                  />
                  <br />
                  <formInput
                    class="customInput"
                    title="Cell: Inwoner in gemeente, voorval buiten gemeente"
                    :model="form"
                    :modelKey="
                      'rapportage_categorieen|' +
                      category.key +
                      '|' +
                      sub_category +
                      '|melder_in_gemeente'
                    "
                    label="on-border"
                    placeholder="Bijvoorbeeld A4"
                  />
                  <br />
                  <formInput
                    class="customInput"
                    title="Cell: totaal inwoners"
                    :model="form"
                    :modelKey="
                      'rapportage_categorieen|' +
                      category.key +
                      '|' +
                      sub_category +
                      '|totaal_inwoners'
                    "
                    label="on-border"
                    placeholder="Bijvoorbeeld A4"
                  />
                  <br />
                  <formInput
                    class="customInput"
                    title="Cell: Inwoner buiten gemeente, voorval in gemeente"
                    :model="form"
                    :modelKey="
                      'rapportage_categorieen|' +
                      category.key +
                      '|' +
                      sub_category +
                      '|voorval_in_gemeente'
                    "
                    label="on-border"
                    placeholder="Bijvoorbeeld A4"
                  />
                  <br />
                  <formInput
                    class="customInput"
                    title="Cell: totaal in gemeente"
                    :model="form"
                    :modelKey="
                      'rapportage_categorieen|' +
                      category.key +
                      '|' +
                      sub_category +
                      '|totaal_in_gemeente'
                    "
                    label="on-border"
                    placeholder="Bijvoorbeeld A4"
                  />
                </div>
              </div>
              <hr />
            </span>
          </div>
        </div>
        <footer class="card-footer">
          <span class="card-footer-item">
            <button
              :class="{
                'is-loading': ajaxloading.save_variables,
              }"
              class="button is-primary is-rounded"
              @click="saveVariables()"
            >
              Opslaan
            </button></span
          >
        </footer>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    category: {
      required: true,
      type: Object,
    },
    open: {
      required: true,
      type: String,
    },
    form: {
      required: true,
      type: Object,
    },
    showVariables: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {
    computedSettings() {
      return this.category.sub_categorien;
    },
  },
  methods: {
    saveVariables() {
      this.$emit("save", this.category.key);
    },
    toggleOpen() {
      this.$emit("toggle", this.category.naam);
    },
  },
  mounted() {},
};
</script>
