<template>
  <div>
    <b-table
      :data="formats"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      class="pointer"
    >
      <b-table-column label="#" v-slot="props">
        {{ props.row.id }}
      </b-table-column>

      <b-table-column label="Naam" v-slot="props">
        <b-tooltip label="Naam veranderen">
          <a
            @click="
              $router.push({
                name: 'update-format-name',
                params: { format: props.row.id },
              })
            "
          >
            {{ props.row.naam }}
          </a>
        </b-tooltip>
      </b-table-column>

      <b-table-column label="Bestandsnaam" v-slot="props">
        <b-tooltip label="Bestand downloaden">
          <a @click="downloadFormat(props.row)">{{
            props.row.bestands_naam
          }}</a>
        </b-tooltip>
      </b-table-column>

      <b-table-column label="Geupload" v-slot="props">
        {{ formatDatabaseDate(props.row.updated_at) }}
        {{ formatDatabaseTime(props.row.updated_at) }}
      </b-table-column>

      <b-table-column label="Acties" v-slot="props">
        <b-tooltip label="Instellingen wijzigen">
          <button
            class="button is-primary is-small"
            @click="
              $router.push({
                name: 'update-format-settings',
                params: { format: props.row.id },
              })
            "
          >
            Instellingen
          </button></b-tooltip
        >&nbsp;

        <b-tooltip label="Nieuw excel format uploaden">
          <button
            class="button is-primary is-small"
            @click="
              $router.push({
                name: 'update-format-file',
                params: { format: props.row.id },
              })
            "
          >
            Bestand wijzigen
          </button></b-tooltip
        >&nbsp;
        <b-tooltip label="Format en instellingen dupliceren">
          <button
            class="button is-primary is-small"
            @click="duplicateFormat(props.row)"
          >
            Dupliceren
          </button></b-tooltip
        >&nbsp;
        <b-tooltip label="Format verwijderen">
          <button
            class="button is-danger is-small"
            @click="deleteDump(props.row)"
          >
            Delete
          </button>
        </b-tooltip>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    formats: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    deleteDump(dump) {
      this.$buefy.dialog.confirm({
        title: "Delete format",
        message:
          "Weet u zeker dat u dit format wilt verwijderen? <br> Deze actie kan niet ongedaan gemaakt worden.",
        confirmText: "Delete format",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store.dispatch("formats/deleteFormat", dump).then(() => {
            this.$store.dispatch("createFlashBanner", "Format deleted!");
          }),
      });
    },
    duplicateFormat(format) {
      this.$buefy.dialog.confirm({
        title: "Dupliceer format",
        message: "Wilt u format met naam: " + format.naam + " dupliceren?",
        confirmText: "Dupliceer",
        type: "is-info",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch("formats/duplicateFormat", format)
            .then(() =>
              this.$store.dispatch("createFlashBanner", "Format duplicated.")
            ),
      });
    },
    downloadFormat(format) {
      this.$store.dispatch("formats/downloadFormat", format);
    },
  },
  mounted() {},
};
</script>
