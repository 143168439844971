import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import state from "./state";
import * as mutations from "./mutations";
import * as getters from "./getters";
import * as actions from "./actions";

import auth from "@/store/auth";
import gebruikers from "@/store/gebruikers";
import rapportages from "@/store/rapportages";
import uploads from "@/store/uploads";
import formats from "@/store/formats";

export default new Vuex.Store({
  modules: {
    auth,
    gebruikers,
    uploads,
    formats,
    rapportages,
  },
  state,
  mutations,
  getters,
  actions,
});
