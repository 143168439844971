<template>
  <div>
    <span class="title is-4">Data dumps</span>&nbsp;
    <br />
    <br />
    <button
      class="button is-fullwidth is-primary"
      @click="$router.push({ name: 'create-data-dump' })"
    >
      Upload nieuwe data dump
    </button>
    <br />
    <datadumpTable :dumps="datadumps" />
  </div>
</template>

<script>
import datadumpTable from "./DatadumpTable.vue";

export default {
  components: {
    datadumpTable,
  },
  props: [],
  data() {
    return {};
  },
  computed: {
    datadumps() {
      return this.$store.getters["uploads/datadumps"];
    },
  },
  methods: {},
  mounted() {},
};
</script>
