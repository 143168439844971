<template>
  <div>
    <b-table
      :data="rapportages"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      class="pointer"
    >
      <b-table-column label="#" v-slot="props">
        {{ props.row.id }}
      </b-table-column>

      <b-table-column label="Aangemaakt op" v-slot="props">
        {{ formatDatabaseDate(props.row.created_at) }}
        {{ formatDatabaseTime(props.row.created_at) }}
      </b-table-column>

      <b-table-column label="Acties" v-slot="props">
        <b-tooltip label="Rapportage downloaden">
          <button
            class="button is-primary is-small"
            @click="downloadRapportage(props.row)"
          >
            Download
          </button>
        </b-tooltip>
        &nbsp;
        <b-tooltip label="Rapportage verwijderen">
          <button
            class="button is-danger is-small"
            :class="{ 'is-loading': ajaxloading.delete_rapportage }"
            @click="deleteRapportage(props.row)"
          >
            Delete
          </button>
        </b-tooltip>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    rapportages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    deleteRapportage(rapportage) {
      this.$buefy.dialog.confirm({
        title: "Delete rapportage",
        message:
          "Weet u zeker dat u deze rapportage wilt verwijderen? <br> Deze actie kan niet ongedaan gemaakt worden.",
        confirmText: "Delete format",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch("rapportages/deleteRapportage", rapportage)
            .then(() => {
              this.$store.dispatch(
                "createFlashBanner",
                "Raportage verwijderd!"
              );
            }),
      });
    },

    downloadRapportage(rapportage) {
      this.$store.dispatch("rapportages/downloadRapportage", rapportage);
    },
  },
  mounted() {},
};
</script>
