export const setFormats = function (state, payload) {
  state.formats = payload;
};

export const setNewFormat = function (state, payload) {
  state.formats.push(payload);
};

export const deleteFormat = function (state, payload) {
  const array = state.formats;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};

export const updateFormat = function (state, payload) {
  const array = state.formats;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1, payload);
};
