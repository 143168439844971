<template>
  <div>
    <div class="title is-4">Rapportages</div>
    <button
      class="button is-fullwidth is-primary"
      @click="$router.push({ name: 'rapportage-toevoegen' })"
    >
      Creëer nieuwe rapportage
    </button>
    <br />
    <rapportageTable :rapportages="rapportages" />
  </div>
</template>

<script>
import rapportageTable from "./RapportageTable.vue";

export default {
  components: {
    rapportageTable,
  },
  props: [],
  data() {
    return {};
  },
  computed: {
    rapportages() {
      const rapportages = this.$store.getters["rapportages/rapportages"];

      rapportages.sort(function (a, b) {
        return b.id - a.id;
      });

      return rapportages;
    },
  },
  methods: {},
  mounted() {},
};
</script>
