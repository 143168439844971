<template>
  <div>
    <div class="box container">
      <!-- <b-field label="Registraties">
        <table style="width: 80%">
          <tr>
            <td>Registraties in behandeling</td>
            <td>{{ registratieStats.registraties_in_behandeling }}</td>
          </tr>
          <tr>
            <td>Afgehandelde registraties</td>
            <td>{{ registratieStats.afgehandelde_registraties }}</td>
          </tr>
          <tr>
            <td>Totale registraties</td>
            <td>{{ registratieStats.totale_registraties }}</td>
          </tr>
        </table>
      </b-field> -->
      <br />
      <div class="columns">
        <div
          class="column tile-button"
          @click="$router.push({ name: 'uploads' })"
        >
          <div class="">Data dumps</div>
        </div>
        <div
          class="column tile-button"
          @click="$router.push({ name: 'rapportages-index' })"
        >
          <p>Rapportages</p>
        </div>
        <!-- <div class="column tile-button">
          <p>Rapportages</p>
        </div> -->
      </div>

      <br v-if="user.role === 'admin'" />

      <div class="columns" v-if="user.role == 'admin'">
        <div
          class="column tile-button"
          @click="$router.push({ name: 'formats-index' })"
        >
          <div class="">Formats</div>
        </div>
        <div
          class="column tile-button"
          @click="$router.push({ name: 'gebruikers-index' })"
        >
          <p>Gebruikers</p>
        </div>
        <!-- <div class="column tile-button">
          <p>Rapportages</p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    // registratieStats() {
    //   return this.$store.getters["rapportages/registratieStats"];
    // }
  },
  methods: {
    tileClicked(routeName) {
      this.$router.push({ name: routeName });
    },
  },
  mounted() {},
};
</script>
