<style scoped>
.column {
  margin-bottom: 0px !important;
}
.columns {
  margin-bottom: 0px !important;
}
.customInput {
  margin-bottom: 0px !important;
}
</style>

<template>
  <div>
    <div class="card">
      <header class="card-header pointer" @click="toggleOpen(category)">
        <p class="card-header-title">{{ hoofdletter(category) }}</p>
      </header>
      <span v-if="open === category">
        <div class="card-content">
          <div class="content">
            <span v-if="computedSettings">
              <span
                v-for="variable in Object.keys(computedSettings)"
                :key="variable.naam"
              >
                <!-- <b-switch
                  class="customInput"
                  v-if="computedSettings[variable - 1].type === 'switch'"
                  v-model="form[computedSettings[variable - 1].key]"
                >
                  {{ computedSettings[variable - 1].name }}
                </b-switch>

                <br v-if="computedSettings[variable - 1].type === 'switch'" />

                <formEditor
                  :value="form[computedSettings[variable - 1].key]"
                  class="customInput"
                  :model="form"
                  :title="computedSettings[variable - 1].name"
                  :modelKey="computedSettings[variable - 1].key"
                  v-if="computedSettings[variable - 1].type === 'editor'"
                /> -->

                <div
                  class="columns"
                  v-if="computedSettings[variable].type === 'input'"
                >
                  <div class="column">
                    <formInput
                      class="customInput"
                      :title="computedSettings[variable].naam"
                      :model="form"
                      :modelKey="computedSettings[variable].key"
                      label="on-border"
                      :placeholder="computedSettings[variable].placeholder"
                    />
                  </div>
                </div>

                <!-- <formCheckbox
                  v-if="computedSettings[variable - 1].type === 'checkbox'"
                  class="customInput"
                  :title="computedSettings[variable - 1].name"
                  :model="form"
                  :modelKey="computedSettings[variable - 1].key"
                  :labelHidden="true"
                  :placeholder="computedSettings[variable - 1].name"
                />

                <formSelect
                  v-if="computedSettings[variable - 1].type === 'select'"
                  class="customInput"
                  :selectOptions="computedSettings[variable - 1].options"
                  :title="computedSettings[variable - 1].name"
                  :model="form"
                  label="on-border"
                  :modelKey="computedSettings[variable - 1].key"
                  :placeholder="computedSettings[variable - 1].name"
                /> -->

                <br />
              </span>
            </span>
          </div>
        </div>
        <footer class="card-footer">
          <span class="card-footer-item">
            <button
              :class="{
                'is-loading': ajaxloading.save_variables,
              }"
              class="button is-primary is-rounded"
              @click="saveVariables()"
            >
              Opslaan
            </button></span
          >
        </footer>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    category: {
      required: true,
      type: String,
    },
    open: {
      required: true,
      type: String,
    },
    settings: {
      required: true,
      type: Object,
    },
    form: {
      required: true,
      type: Object,
    },
    showVariables: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {
    computedSettings() {
      return this.settings.datadump_waarden;
    },
  },
  methods: {
    saveVariables() {
      this.$emit("save", this.category);
    },
    toggleOpen() {
      this.$emit("toggle", this.category);
    },
  },
  mounted() {},
};
</script>
