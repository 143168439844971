<template>
  <div>
    <b-field :label="computedTitle">
      <span v-if="!preview">
        <b-field class="file">
          <b-upload v-model="file" expanded>
            <a :class="['button is-rounded is-primary is-fullwidth ' + size]">
              <b-icon icon="upload"></b-icon>
              <span>{{ computedPictureName || "Click to upload" }}</span>
            </a>
          </b-upload>
        </b-field>
        <p class="help is-danger" v-if="validation[modelKey]">
          {{ validation[modelKey][0] }}
        </p>
      </span>
      <span v-else>
        <div class="columns">
          <div class="column is-6">
            <b-field>
              <b-upload v-model="file" expanded @input="fileChanged()">
                <a
                  :class="['button is-rounded is-primary is-fullwidth ' + size]"
                >
                  <b-icon icon="upload"></b-icon>
                  <span>{{ computedPictureName || "Click to upload" }}</span>
                </a>
              </b-upload>
            </b-field>
            <p class="help is-danger" v-if="validation[modelKey]">
              {{ validation[modelKey][0] }}
            </p>
          </div>

          <div class="column is-6" v-if="previewImage">
            <div class="box has-text-centered">
              <button
                class="delete is-small is-pulled-right"
                type="button"
                @click="deletePicture()"
              ></button>

              <!-- <div class="columns"> -->
              <!-- <div class="column is-6"> -->
              <img
                v-if="previewImage.url && !cropper"
                :style="'height:' + height + 'px;'"
                :src="previewImage.url"
              />

              <cropper
                :style="'height:' + height + 'px;'"
                v-if="previewImage && cropper"
                class="cropper"
                :src="previewImage.url"
                :resizeImage="{ wheel: false }"
                :stencil-props="{
                  aspectRatio: 3 / 4,
                }"
                @change="change"
              ></cropper>

              <br />
              <!-- </div> -->
              <!-- <div class="column"> -->

              <!-- </div> -->
              <!-- </div> -->
            </div>
            <div class="box has-text-centered">
              <button
                class="delete is-small is-pulled-right"
                type="button"
                @click="deletePicture()"
              ></button>
              lololololo
            </div>
          </div>
        </div>
      </span>
    </b-field>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";

export default {
  components: {},
  props: {
    cropper: {
      default: true,
      type: Boolean,
    },
    preview: {
      default: false,
      type: Boolean,
    },
    title: {
      type: String,
    },
    model: {
      required: true,
      type: FormData,
    },
    modelKey: {
      required: true,
      type: String,
    },
    placeholder: {
      type: String,
    },
    size: {
      default: "is-normal",
      type: String,
    },
    height: {
      default: "10",
      type: String,
    },
  },
  data() {
    return {
      file: {},
      previewImage: null,
    };
  },
  computed: {
    computedTitle() {
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    },
    computedPictureName() {
      if (this.file) {
        return this.file.name;
      } else {
        return "";
      }
    },
  },
  methods: {
    change({ coordinates, canvas }) {
      this.model.append(this.modelKey + "_width", coordinates.width);
      this.model.append(this.modelKey + "_height", coordinates.height);
      this.model.append(this.modelKey + "_x", coordinates.left);
      this.model.append(this.modelKey + "_y", coordinates.top);
    },
    fileChanged() {
      this.clearValidationError();
      this.model.append(this.modelKey, this.file, this.file.name);
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          let filepreview = {
            name: this.file.name,
            url: reader.result,
          };
          this.previewImage = filepreview;
        },
        false
      );
      reader.readAsDataURL(this.file);
    },
    deletePicture() {
      this.previewImage = null;
      this.model[this.modelKey] = null;
      this.file = null;
    },
    clearValidationError() {
      this.$store.dispatch("clearValidationError", this.modelKey);
    },
  },
  mounted() {
    this.file = this.model[this.modelKey];
  },
};
</script>
