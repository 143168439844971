<template>
  <div>
    <div class="title">Vervang format bestand</div>

    <b-field
      v-if="selectedFile === null"
      :type="{
        'is-danger': computedValidationMessage(),
      }"
      :message="computedValidationMessage()"
    >
      <b-upload
        @input="validation.extension = null"
        v-model="selectedFile"
        drag-drop
        expanded
        accept=".xlsx"
      >
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon icon="upload" size="is-large"></b-icon>
            </p>
            <p>Sleep bestand hierin, of klik om een bestand te kiezen</p>
          </div>
        </section>
      </b-upload>
    </b-field>
    <span v-else>
      {{ selectedFile.name }}&nbsp;
      <button
        class="button is-danger is-small is-pulled-right"
        @click="selectedFile = null"
      >
        Annuleren
      </button>
    </span>
    <br />
    <span class="help is-danger" v-if="validation.extension">{{
      validation.extension[0]
    }}</span>
    <br />
    <button
      :class="{ 'is-loading': ajaxloading.uploading_data_dump }"
      class="button is-primary is-fullwidth"
      @click="upload()"
    >
      Uploaden
    </button>

    <br />
    <button
      :class="{ 'is-loading': ajaxloading.uploading_data_dump }"
      class="button is-danger is-fullwidth"
      @click="cancelUpdate()"
    >
      Annuleren
    </button>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {},
      selectedFile: null,
    };
  },
  computed: {
    formats() {
      return this.$store.getters["formats/formats"];
    },
    format() {
      const format = this.formats.find(
        (a) => a.id == this.$router.currentRoute.params.format
      );

      if (format) {
        return format;
      }

      return {};
    },
  },
  methods: {
    computedValidationMessage() {
      if (this.validation.file) {
        return this.validation.file;
      }
    },

    upload() {
      console.log(this.format);
      const formData = new FormData();
      if (this.selectedFile) {
        formData.append("file", this.selectedFile);
      }
      if (this.format && this.format.id) {
        formData.append("id", this.format.id);
      }
      this.$store.dispatch("formats/replaceFormat", formData).then(() => {
        this.$router.go(-1);
      });
    },
    cancelUpdate() {
      this.$router.go(-1);
      this.$store.dispatch("formats/refreshFormat", this.format);
    },
  },
  mounted() {},
};
</script>
