<template>
  <div>
    <b-field
      :label="hoofdletter(computedTitle)"
      :label-position="label"
      :type="{ 'is-danger': validation[modelKey] }"
      :message="validation[modelKey]"
    >
      <b-select
        :size="size"
        v-if="computedSelectKey"
        :placeholder="placeholder"
        expanded
        @input="clearValidationError()"
        v-model="model[modelKey]"
      >
        <option
          v-for="option of selectOptions"
          :key="option.id"
          :value="option"
        >
          {{ computedOption(option) }}
        </option>
      </b-select>

      <b-select
        :size="size"
        v-else
        :placeholder="placeholder"
        expanded
        @input="clearValidationError()"
        v-model="model[modelKey]"
      >
        <option v-for="option of selectOptions" :key="option" :value="option">
          {{ option }}
        </option>
      </b-select>
    </b-field>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
    },
    model: {
      required: true,
      type: Object,
    },
    modelKey: {
      required: true,
      type: String,
    },
    placeholder: {
      type: String,
    },
    size: {
      default: "is-normal",
      type: String,
    },
    label: {
      default: "",
      type: String,
    },
    type: {
      default: "input",
      type: String,
    },
    selectOptions: {
      required: true,
      type: Array,
    },
    selectKey: {},
    selectValue: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    computedTitle() {
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    },
    computedSelectKey() {
      if (this.selectKey) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    computedOption(option) {
      var result = "";

      for (let str of this.selectKey) {
        if (str in option) {
          result = result + option[str];
        } else {
          result = result + str;
        }
      }

      return result;
    },
    clearValidationError() {
      this.$emit("change");
      this.$store.dispatch("clearValidationError", this.modelKey);
    },
  },
  mounted() {},
};
</script>
