import axios from "axios";

export const axiosApi = function ({ dispatch, commit }, payload) {
  return dispatch("axiosWeb", payload);
};

export const axiosWeb = function ({ commit }, payload) {
  commit("loadingOn", payload.loading);
  return axios({
    url: process.env.VUE_APP_API_URL + "/web/" + payload.url,
    method: payload.method,
    data: payload.data,
    withCredentials: true,
  })
    .then(function (response) {
      commit("loadingOff", payload.loading);
      return Promise.resolve(response);
    })
    .catch(function (error) {
      commit("loadingOff", payload.loading);
      return Promise.reject(error);
    });
};

export const openWebLink = function ({ commit }, payload) {
  return location.assign(process.env.VUE_APP_API_URL + "/web/" + payload);
};

export const loadingOn = function ({ commit }, payload) {
  commit("loadingOn", payload);
};

export const loadingOff = function ({ commit }, payload) {
  commit("loadingOff", payload);
};

export const setValidationErrors = function ({ commit }, payload) {
  commit("setValidationErrors", payload);
};

export const clearValidationErrors = function ({ commit }) {
  commit("setValidationErrors", {});
};

export const createFlashBanner = function ({ commit, dispatch }, payload) {
  var message = String;
  var timeout = Number;

  if (typeof payload === "string") {
    message = payload;
    timeout = 2500;
  } else if (payload.timeout) {
    message = payload.message;
    timeout = payload.timeout * 1000;
  } else {
    message = payload.message;
    timeout = 2500;
  }

  window.scrollTo(0, 0);

  const data = {
    message: message,
    type: payload.type,
    html: payload.html,
  };

  commit("setFlashBanner", data);
  setTimeout(function () {
    dispatch("destroyFlashBanner");
  }, timeout);
};

export const clearValidationError = function ({ commit }, payload) {
  commit("clearValidationError", payload);
};

export const destroyFlashBanner = function ({ commit }, payload) {
  commit("setFlashBanner", null);
};
