export const getGemeenteFromDatadump = function ({ dispatch, commit }) {
  const data = {};
  data.url = "get_gemeenten_from_datadump";
  data.method = "GET";
  data.loading = "getting_datadump_gemeenten";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getRapportages = function ({ dispatch, commit }) {
  const data = {};
  data.url = "get_rapportages";
  data.method = "GET";
  data.loading = "getting_datadump_gemeenten";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setRapportages", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteRapportage = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "delete_rapportage/" + payload.id;
  data.method = "post";
  data.loading = "delete_rapportage";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("deleteRapportage", payload);

      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const createRapportage = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "create_rapportage";
  data.method = "post";
  data.loading = "create_rapportage";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setNewRapportage", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const downloadRapportage = function ({ dispatch, commit }, payload) {
  const url = "download_rapportage/" + payload.id;
  return dispatch("openWebLink", url, { root: true });
};
