<template>
  <div>
    <section class="hero is-login is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-4">
              <div class="container box">
                <!-- email form -->
                <form @submit.prevent="passwordChangeButtonClicked()">
                  <span
                    class="has-text-centered title is-3"
                    style="color: black"
                    >Kies een nieuw wachtwoord</span
                  >

                  <br />
                  <br />
                  <span class="has-text-centered is-5"
                    >Email: {{ form.email }}</span
                  >

                  <br />
                  <br />

                  <b-field
                    label="Password"
                    :type="{ 'is-danger': validation.password }"
                    :message="{ [validation.password]: validation.password }"
                  >
                    <b-input
                      type="password"
                      rounded
                      placeholder="Password"
                      password-reveal
                      v-model="form.password"
                    >
                    </b-input>
                  </b-field>

                  <b-field
                    label="Password bevestigen"
                    :type="{ 'is-danger': validation.password_confirmation }"
                    :message="{
                      [validation.password_confirmation]:
                        validation.password_confirmation,
                    }"
                  >
                    <b-input
                      type="password"
                      rounded
                      placeholder="Password bevestigen"
                      password-reveal
                      v-model="form.password_confirmation"
                    >
                    </b-input>
                  </b-field>

                  <b-button
                    native-type="submit"
                    :loading="ajaxloading.changingpassword"
                    type="is-primary is-rounded"
                    >Wachtwoord veranderen</b-button
                  >
                  <p class="help">
                    <router-link tag="a" :to="{ name: 'inloggen' }"
                      >Terug naar inloggen</router-link
                    >
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {
        email: "",
        token: null,
      },
    };
  },
  computed: {},
  methods: {
    passwordChangeButtonClicked() {
      this.$store
        .dispatch("auth/changePassword", this.form)
        .then((response) => {
          if (response.data === "wachtwoord aangepast") {
            this.$store.dispatch("createFlashBanner", {
              message:
                "U password is succesvol aangepast. Log in om verder te gaan",
              timeout: 5,
            });
            this.$router.push({ name: "inloggen" });
          }

          if (response.data === "token is ongeldig") {
            this.$store.dispatch("createFlashBanner", {
              message: "Uw wachtwoord-reset link is ongeldig of verlopen.",
              timeout: 5,
            });
            this.$router.push({ name: "wachtwoord-resetten" });
          }
        });
    },
  },
  mounted() {
    if (this.$route.params.token) {
      this.form.token = this.$route.params.token;
      this.form.email = this.$route.params.email;
    }
  },
};
</script>

<style scoped></style>
