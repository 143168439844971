<style scoped>
.column {
  margin-bottom: 0px !important;
  padding-bottom: 4px !important;
}
.columns {
  margin-bottom: 0px !important;
}
.customInput {
  margin-bottom: 0px !important;
}

.rotateText {
  transform: rotate(-90deg); /* Equal to rotateZ(45deg) */
}
</style>

<template>
  <div>
    <div class="card">
      <header class="card-header pointer" @click="toggleOpen('gemeenten')">
        <p class="card-header-title">{{ hoofdletter("gemeenten") }}</p>
      </header>
      <span v-if="open === 'gemeenten'">
        <div class="card-content">
          <div class="content">
            <ul>
              <span v-for="gemeente in selected_gemeenten" :key="gemeente">
                <div class="columns">
                  <div class="column">
                    <li>
                      {{ gemeente }}
                    </li>
                  </div>
                  <div class="column">
                    <button
                      class="button is-small is-danger is-pulled-right"
                      @click="removeGemeente(gemeente)"
                    >
                      Verwijderen
                    </button>
                  </div>
                </div>
              </span>
            </ul>
            <br v-if="selected_gemeenten.length" />
            <formAutocomplete
              :model="form"
              :modelKey="'selectedGemeente'"
              :options="gemeenten_not_selected"
              label="on-border"
              title="Kies een gemeente"
              placeholder="Amsterdam"
              v-on:selected="newGemeenteSelected"
              notFoundText="Geen gemeente gevonden"
              :clearOnSelect="true"
            />
          </div>
        </div>
        <footer class="card-footer" v-if="!disableOpslaan">
          <span class="card-footer-item">
            <button
              :class="{
                'is-loading': ajaxloading.save_variables,
              }"
              class="button is-primary is-rounded"
              @click="saveVariables()"
            >
              Opslaan
            </button></span
          >
        </footer>
      </span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  props: {
    open: {
      required: true,
      type: String,
    },
    form: {
      required: true,
      type: Object,
    },
    formatProp: {
      required: false,
      type: Object,
    },
    showVariables: {
      default: false,
      type: Boolean,
    },
    disableOpslaan: {
      default: false,
    },
    settings: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      selected_gemeenten: [],
    };
  },
  computed: {
    gemeenten_not_selected() {
      return this.settings.gemeenten.filter(
        (a) => !this.selected_gemeenten.includes(a)
      );
    },
    formats() {
      return this.$store.getters["formats/formats"];
    },
    format() {
      if (this.formatProp) {
        return this.formatProp;
      }
      const format = this.formats.find(
        (a) => a.id == this.$router.currentRoute.params.format
      );

      if (format) {
        return format;
      }

      return {};
    },
  },
  methods: {
    saveVariables() {
      this.selected_gemeenten.sort();
      this.$emit("save", this.selected_gemeenten);
    },
    toggleOpen() {
      this.$emit("toggle", "gemeenten");
    },
    newGemeenteSelected(gemeente) {
      if (gemeente) {
        this.selected_gemeenten.push(gemeente);
      }
    },
    removeGemeente(gemeente) {
      const array = this.selected_gemeenten;
      const id = gemeente;
      const index = array.findIndex((a) => a === id);
      array.splice(index, 1);
      this.selected_gemeenten.sort();
    },
    setGemeenten() {
      this.selected_gemeenten = this.format.gemeenten.map((a) => a.gemeente);
      this.selected_gemeenten.sort();
    },
  },
  mounted() {
    if (this.format && this.format.gemeenten) {
      this.setGemeenten();
    } else {
      this.$store.dispatch("formats/getFormats").then(() => {
        this.setGemeenten();
      });
    }
  },
};
</script>
