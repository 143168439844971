<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.table-icons {
  float: right;
}
</style>

<template>
  <div>
    <div class="box container">
      <div class="users-title">Rapportages</div>

      <br />
      <br />
      <div>
        <form action="" @submit.prevent="createRapportage()">
          <br />
          <!-- <div class="columns">
            <div class="column">
              <b-field id="datum_incident" label="Van">
                <b-datepicker
                  size="is-small"
                  v-model="form.begindatum"
                  locale="nl-NL"
                  rounded
                  placeholder="Selecteer begindatum van rapportage"
                  icon="calendar"
                  :first-day-of-week="1"
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column">
              <b-field id="datum_incident" label="Tot">
                <b-datepicker
                  size="is-small"
                  v-model="form.einddatum"
                  locale="nl-NL"
                  rounded
                  placeholder="Selecteer einddatum van rapportage"
                  icon="calendar"
                  :first-day-of-week="1"
                >
                </b-datepicker>
              </b-field>
            </div>
          </div> -->

          <formSelect
            label="on-border"
            :model="form"
            title="Selecteer rapportage jaar"
            modelKey="rapportage_jaar"
            :selectOptions="rapportage_jaren"
          />

          <br />

          <formSelect
            v-if="form.rapportage_jaar"
            label="on-border"
            :model="form"
            title="Selecteer datadump"
            modelKey="datadump"
            :selectOptions="verwerktedataDumps"
            :selectKey="['naam']"
          />

          <br />

          <formSelect
            @change="setGemeenten()"
            v-if="form.datadump"
            label="on-border"
            :model="form"
            modelKey="format"
            :selectOptions="formats"
            :selectKey="['naam']"
          />

          <br />

          <div class="content" v-if="form.format">
            <ul>
              <span v-for="gemeente in selected_gemeenten" :key="gemeente">
                <div class="columns">
                  <div class="column">
                    <li>
                      {{ gemeente }}
                    </li>
                  </div>
                  <div class="column">
                    <button
                      class="button is-small is-danger is-pulled-right"
                      @click="removeGemeente(gemeente)"
                    >
                      Verwijderen
                    </button>
                  </div>
                </div>
              </span>
            </ul>
            <br v-if="selected_gemeenten.length" />
            <formAutocomplete
              :model="form"
              :modelKey="'selectedGemeente'"
              :options="gemeenten_not_selected"
              label="on-border"
              title="Voeg gemeenten toe"
              placeholder="Amsterdam"
              validationKey="selected_gemeenten"
              v-on:selected="newGemeenteSelected"
              notFoundText="Geen gemeente gevonden"
              :clearOnSelect="true"
            />
          </div>

          <br v-if="form.format" />

          <b-button
            expanded
            v-if="form.format"
            native-type="submit"
            :loading="ajaxloading.create_rapportage"
            type="is-primary is-rounded"
            >Rapportage maken</b-button
          >
          <br v-if="form.format" />
          <b-button
            expanded
            :loading="ajaxloading.create_rapportage"
            type="is-rounded"
            @click="$router.go(-1)"
            >Terugkeren</b-button
          >
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      selected_gemeenten: [],
      rapportage_jaren: [],
      form: {
        rapportage_jaar: "",
      },
      open: "",
    };
  },
  computed: {
    settings() {
      return this.$store.getters["auth/settings"];
    },
    datadumps() {
      return this.$store.getters["uploads/datadumps"];
    },
    verwerktedataDumps() {
      return this.datadumps.filter((a) => a.status === "verwerkt");
    },
    formats() {
      return this.$store.getters["formats/formats"];
    },
    gemeenten_not_selected() {
      if (this.settings && this.settings.gemeenten) {
        return this.settings.gemeenten.filter(
          (a) => !this.selected_gemeenten.includes(a)
        );
      } else {
        return [];
      }
    },
  },
  methods: {
    // datadumpChanged() {
    //   this.$store
    //     .dispatch("rapportages/getGemeenteFromDatadump")
    //     .then((response) => {
    //       console.log(response.data);
    //     });
    // },
    toggleCategory(category) {
      if (this.open === category) {
        return (this.open = "");
      }

      this.open = category;
    },
    newGemeenteSelected(gemeente) {
      if (gemeente) {
        this.selected_gemeenten.push(gemeente);
      }
    },
    removeGemeente(gemeente) {
      const array = this.selected_gemeenten;
      const id = gemeente;
      const index = array.findIndex((a) => a === id);
      array.splice(index, 1);
    },
    setGemeenten() {
      this.selected_gemeenten = this.form.format.gemeenten.map(
        (a) => a.gemeente
      );
      this.selected_gemeenten.sort();
    },
    createRapportage() {
      this.form.selected_gemeenten = this.selected_gemeenten;
      this.$store
        .dispatch("rapportages/createRapportage", this.form)
        .then(() => {
          this.$store.dispatch("createFlashBanner", "Rapportage aangemaakt.");
          this.$router.go(-1);
        });
    },
    setRapportageYears() {
      var date = new Date().getFullYear();
      this.rapportage_jaren.push(
        ...[
          date - 8,
          date - 7,
          date - 6,
          date - 5,
          date - 4,
          date - 3,
          date - 2,
          date - 1,
          date,
          date + 1,
          date + 2,
          date + 3,
          date + 4,
          date + 5,
          date + 6,
          date + 7,
        ]
      );
      this.form.rapportage_jaar = date - 1;
    },
  },
  created() {
    this.form.begindatum = new Date(new Date().getFullYear(), 0, 1);
    this.form.einddatum = new Date(new Date().getFullYear(), 11, 31);
  },
  watch: {},
  mounted() {
    this.setRapportageYears();
  },
};
</script>
