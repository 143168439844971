export const setNewDataDump = function (state, payload) {
  state.datadumps.push(payload);
};

export const setDataDumps = function (state, payload) {
  state.datadumps = payload;
};

export const deleteDataDump = function (state, payload) {
  const array = state.datadumps;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};

export const refreshDatadump = function (state, payload) {
  const array = state.datadumps;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1, payload);
};
