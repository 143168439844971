export const getFormats = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get_report_formats";
  data.method = "GET";
  data.loading = "getting_formats";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setFormats", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const createFormat = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "create_report_format";
  data.method = "POST";
  data.loading = "getting_formats";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setNewFormat", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const downloadFormat = function ({ dispatch, commit }, payload) {
  const url = "download_format/" + payload.id;
  return dispatch("openWebLink", url, { root: true });
};

export const deleteFormat = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "delete_report_format/" + payload.id;
  data.method = "POST";
  data.loading = "deleting_format";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("deleteFormat", payload);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const updateFormName = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "rename_report_format/" + payload.id;
  data.method = "POST";
  data.loading = "rename_format";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const replaceFormat = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "replace_report_format/" + payload.get("id");
  data.method = "POST";
  data.loading = "replaceFormat";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("updateFormat", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const refreshFormat = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "refresh_report_format/" + payload.id;
  data.method = "GET";
  data.loading = "refresh_format";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("updateFormat", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const duplicateFormat = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "duplicate_report_format/" + payload.id;
  data.method = "post";
  data.loading = "refresh_format";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setNewFormat", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const saveFormatGeneralSettings = function (
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url = "save_format_general_settings/";
  data.method = "post";
  data.loading = "save_variables";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const saveFormatCategorySettings = function (
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url = "save_format_category_settings/";
  data.method = "post";
  data.loading = "save_variables";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const saveFormatGemeenten = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "save_format_gemeenten_settings/";
  data.method = "post";
  data.loading = "save_variables";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("updateFormat", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
