<style lang="scss">
@import "@/styles/main.scss";

.main-background-color {
  width: auto;
  height: auto;
  background-color: blue !important;
  overflow: auto;
  overflow-x: hidden;
}
</style>

<template>
  <div id="app">
    <flashBanner />
    <router-view />
  </div>
</template>

<script>
import flashBanner from "@/components/headers/flashBanner.vue";

export default {
  components: {
    flashBanner,
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
