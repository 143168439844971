import Vue from "vue";
import VueRouter from "vue-router";
import App from "../views/App.vue";
import Home from "@/App.vue";
import Login from "../views/Auth/Login.vue";
import FourZeroFour from "../views/FourZeroFour.vue";

import Passwordreset from "../views/Auth/Passwordreset.vue";
import PasswordChange from "../views/Auth/PasswordChange.vue";
import UserSettings from "@/views/Auth/UserSettings.vue";

import Dashboard from "../views/Dashboard.vue";

import GebruikersBase from "../views/Gebruikers/GebruikersBase.vue";
import GebruikersIndex from "../views/Gebruikers/GebruikersIndex.vue";
import GebruikerToevoegen from "../views/Gebruikers/GebruikerToevoegen.vue";
import GebruikerAanpassen from "../views/Gebruikers/GebruikerAanpassen.vue";

import RapportagesBase from "@/views/Rapportages/RapportagesBase.vue";
import RapportagesIndex from "@/views/Rapportages/RapportagesIndex.vue";
import RapportageToevoegen from "@/views/Rapportages/RapportageToevoegen.vue";

import UploadsBase from "@/views/Uploads/UploadsBase";
import UploadIndex from "@/views/Uploads/UploadIndex";
import CreateDatadump from "@/views/Uploads/CreateDatadump";
import UpdateDatadump from "@/views/Uploads/UpdateDatadump.vue";

import CreateFormat from "@/views/Formats/CreateFormat.vue";
import UpdateFormatSettings from "@/views/Formats/UpdateFormatSettings.vue";
import FormatsBase from "@/views/Formats/FormatsBase.vue";
import FormatsIndex from "@/views/Formats/FormatsIndex.vue";
import UpdateNameFormat from "@/views/Formats/UpdateNameFormat.vue";
import UpdateFormatFile from "@/views/Formats/UpdateFormatFile.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    children: [
      {
        path: "",
        name: "inloggen",
        component: Login,
      },
      {
        path: "wachtwoord-resetten",
        name: "wachtwoord-resetten",
        component: Passwordreset,
      },
      {
        path: "wachtwoord-resetten/:email/:token",
        name: "wachtwoord-veranderen",
        component: PasswordChange,
      },
      {
        path: "app",
        component: App,
        children: [
          {
            path: "",
            name: "app",
            component: Dashboard,
          },
          {
            path: "instellingen",
            name: "instellingen",
            component: UserSettings,
          },

          {
            path: "rapportages",
            component: RapportagesBase,
            children: [
              {
                path: "",
                component: RapportagesIndex,
                name: "rapportages-index",
              },
              {
                path: "toevoegen",
                component: RapportageToevoegen,
                name: "rapportage-toevoegen",
              },
            ],
          },
          {
            path: "data-dumps",
            component: UploadsBase,
            children: [
              {
                path: "",
                component: UploadIndex,
                name: "uploads",
              },
              {
                path: "aanmaken",
                component: CreateDatadump,
                name: "create-data-dump",
              },
              {
                path: "update/:datadump",
                component: UpdateDatadump,
                name: "update-data-dump",
              },
            ],
          },
          {
            path: "formats",
            component: FormatsBase,
            children: [
              {
                path: "",
                component: FormatsIndex,
                name: "formats-index",
              },
              {
                path: "aanmaken",
                component: CreateFormat,
                name: "create-format",
              },
              {
                path: "update-naam/:format",
                component: UpdateNameFormat,
                name: "update-format-name",
              },
              {
                path: "update-format/:format",
                component: UpdateFormatFile,
                name: "update-format-file",
              },
              {
                path: "update/:format/instellingen",
                component: UpdateFormatSettings,
                name: "update-format-settings",
              },
            ],
          },

          {
            path: "gebruikers",
            component: GebruikersBase,
            children: [
              {
                path: "",
                component: GebruikersIndex,
                name: "gebruikers-index",
              },
              {
                path: "toevoegen",
                component: GebruikerToevoegen,
                name: "gebruiker-toevoegen",
              },
              {
                path: ":gebruiker/aanpassen",
                component: GebruikerAanpassen,
                name: "gebruiker-aanpassen",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "*",
    component: FourZeroFour,
    name: "404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "is-active",
  linkExactActiveClass: "is-active",
  routes,
});

export default router;
