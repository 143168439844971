<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}
</style>

<template>
  <div class="content">
    <div class="users-title">Gebruiker {{ gebruiker.name }} aanpassen</div>
    <form @submit.prevent="gebruikerAanpassen()">
      <b-loading v-model="ajaxloading.getEditUser" />
      <br />
      <br />

      <b-field
        label="Naam"
        :type="{ 'is-danger': validation.naam }"
        :message="{ [validation.naam]: validation.naam }"
      >
        <b-input type="input" rounded placeholder="Naam" v-model="form.naam">
        </b-input>
      </b-field>

      <b-field
        label="Email"
        :type="{ 'is-danger': validation.email }"
        :message="{ [validation.email]: validation.email }"
      >
        <b-input
          v-model="form.email"
          rounded
          placeholder="Emailadres"
        ></b-input>
      </b-field>

      <b-field
        label="Rol"
        :type="{ 'is-danger': validation.rol }"
        :message="{ [validation.rol]: validation.rol }"
      >
        <b-select
          v-if="settings.gebruikers"
          placeholder="Selecteer een rol"
          v-model="form.rol"
          rounded
          expanded
        >
          <option
            v-for="rol of settings.gebruikers.rollen"
            :key="rol"
            :value="rol"
          >
            {{ hoofdletter(rol) }}
          </option>
        </b-select>
      </b-field>

      <h5>Toegangsrechten</h5>

      <div class="columns">
        <div class="column">
          <b-checkbox v-model="form.arnhem"> Registraties Arnhem </b-checkbox>
        </div>
        <div class="column">
          <b-checkbox v-model="form.rapportages_arnhem">
            Rapportages Arnhem
          </b-checkbox>
        </div>
        <div class="column">
          <div class="field">
            <b-checkbox v-model="form.sendnotificationarnhem">
              Email nieuwe registratie Arnhem
            </b-checkbox>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <b-checkbox v-model="form.enschede">
            Registraties Enschede
          </b-checkbox>
        </div>
        <div class="column">
          <b-checkbox v-model="form.rapportages_enschede">
            Rapportages Enschede
          </b-checkbox>
        </div>
        <div class="column">
          <div class="field">
            <b-checkbox v-model="form.sendnotification_enschede">
              Email nieuwe registratie Enschede
            </b-checkbox>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <b-checkbox v-model="form.overige_gemeente">
            Registraties Overige gemeente
          </b-checkbox>
        </div>
        <div class="column">
          <b-checkbox v-model="form.rapportages_overige_gemeente">
            Rapportages Overige gemeente
          </b-checkbox>
        </div>
        <div class="column">
          <div class="field">
            <b-checkbox v-model="form.sendnotification_overige_gemeente">
              Email nieuwe registratie Overige gemeente
            </b-checkbox>
          </div>
        </div>
      </div>

      <br />

      <b-button
        native-type="submit"
        :loading="ajaxloading.gebruikerAanpassen"
        type="is-primary is-rounded"
        >Gebruiker aanpassen</b-button
      >&nbsp;

      <b-button type="is-rounded" @click="$router.go(-1)">Annuleren</b-button>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {},
    };
  },
  computed: {
    gebruiker() {
      return this.$store.getters["gebruikers/gebruiker"];
    },
    settings() {
      return this.$store.getters["auth/settings"];
    },
  },
  methods: {
    gebruikerAanpassen() {
      this.$store
        .dispatch("gebruikers/gebruikerAanpassen", this.form)
        .then(() => {
          this.$store.dispatch("createFlashBanner", {
            message: "Gebruiker aangepast.",
            timeout: 3,
          });
          this.$router.push({ name: "gebruikers-index" });
        });
    },
  },
  watch: {
    gebruiker: function (val) {
      this.form = this.gebruiker;
      this.form.naam = this.gebruiker.name;
    },
  },
  mounted() {
    this.$store.dispatch("gebruikers/getUser");
  },
};
</script>
