export const setRegistratieStats = function (state, payload) {
  state.registratieStats = payload;
};

export const setNewRapportage = function (state, payload) {
  state.rapportages.push(payload);
};

export const setRapportages = function (state, payload) {
  state.rapportages = payload;
};

export const deleteRapportage = function (state, payload) {
  const array = state.rapportages;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};
