export const uploadDatadump = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "upload_new_data_dump";
  data.method = "POST";
  data.loading = "uploading_data_dump";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setNewDataDump", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getDataDumps = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get_data_dumps";
  data.method = "GET";
  data.loading = "getting_data_dumps";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setDataDumps", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteDatadump = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "delete_data_dump/" + payload.id;
  data.method = "POST";
  data.loading = "getting_data_dumps";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("deleteDataDump", payload);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const editDatadump = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "edit_data_dump/" + payload.id;
  data.method = "POST";
  data.loading = "edit_data_dump";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const downloadDatadump = function ({ dispatch, commit }, payload) {
  const url = "download_data_dump/" + payload.id;
  return dispatch("openWebLink", url, { root: true });
};

export const refreshDatadump = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "refresh_data_dump/" + payload.id;
  data.method = "POST";
  data.loading = "refresh_datadump";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("refreshDatadump", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
