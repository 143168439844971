<template>
  <div>
    <div class="title">Update naam van format</div>
    <formInput
      class="customInput"
      :model="format"
      modelKey="naam"
      label="on-border"
      placeholder="Naam voor format"
    />

    <br />

    <button
      :class="{ 'is-loading': ajaxloading.rename_format }"
      class="button is-primary is-fullwidth"
      @click="saveName()"
    >
      Opslaan
    </button>

    <br />
    <button
      :class="{ 'is-loading': ajaxloading.refresh_format }"
      class="button is-danger is-fullwidth"
      @click="cancelUpdate()"
    >
      Annuleren
    </button>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {},
    };
  },
  computed: {
    formats() {
      return this.$store.getters["formats/formats"];
    },
    format() {
      const format = this.formats.find(
        (a) => a.id == this.$router.currentRoute.params.format
      );

      if (format) {
        return format;
      }

      return {};
    },
  },
  methods: {
    saveName() {
      this.$store.dispatch("formats/updateFormName", this.format).then(() => {
        this.$router.go(-1);
      });
    },
    cancelUpdate() {
      this.$router.go(-1);
      this.$store.dispatch("formats/refreshFormat", this.format);
    },
  },
  mounted() {},
};
</script>
