var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table',{staticClass:"pointer",attrs:{"data":_vm.formats,"bordered":true,"striped":true,"narrowed":true,"hoverable":true}},[_c('b-table-column',{attrs:{"label":"#"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.id)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Naam"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-tooltip',{attrs:{"label":"Naam veranderen"}},[_c('a',{on:{"click":function($event){return _vm.$router.push({
              name: 'update-format-name',
              params: { format: props.row.id },
            })}}},[_vm._v(" "+_vm._s(props.row.naam)+" ")])])]}}])}),_c('b-table-column',{attrs:{"label":"Bestandsnaam"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-tooltip',{attrs:{"label":"Bestand downloaden"}},[_c('a',{on:{"click":function($event){return _vm.downloadFormat(props.row)}}},[_vm._v(_vm._s(props.row.bestands_naam))])])]}}])}),_c('b-table-column',{attrs:{"label":"Geupload"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm.formatDatabaseDate(props.row.updated_at))+" "+_vm._s(_vm.formatDatabaseTime(props.row.updated_at))+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Acties"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-tooltip',{attrs:{"label":"Instellingen wijzigen"}},[_c('button',{staticClass:"button is-primary is-small",on:{"click":function($event){return _vm.$router.push({
              name: 'update-format-settings',
              params: { format: props.row.id },
            })}}},[_vm._v(" Instellingen ")])]),_vm._v("  "),_c('b-tooltip',{attrs:{"label":"Nieuw excel format uploaden"}},[_c('button',{staticClass:"button is-primary is-small",on:{"click":function($event){return _vm.$router.push({
              name: 'update-format-file',
              params: { format: props.row.id },
            })}}},[_vm._v(" Bestand wijzigen ")])]),_vm._v("  "),_c('b-tooltip',{attrs:{"label":"Format en instellingen dupliceren"}},[_c('button',{staticClass:"button is-primary is-small",on:{"click":function($event){return _vm.duplicateFormat(props.row)}}},[_vm._v(" Dupliceren ")])]),_vm._v("  "),_c('b-tooltip',{attrs:{"label":"Format verwijderen"}},[_c('button',{staticClass:"button is-danger is-small",on:{"click":function($event){return _vm.deleteDump(props.row)}}},[_vm._v(" Delete ")])])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }