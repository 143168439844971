<template>
  <div>
    <b-field
      :label="hoofdletter(computedTitle)"
      :type="{ 'is-danger': validation[modelKey] }"
      :message="validation[modelKey]"
    >
      <b-taginput
        v-model="model[modelKey]"
        @input="clearValidationError()"
        ellipsis
        autocomplete
        :allow-new="false"
        open-on-focus
        :data="filteredTags"
        :field="tagKey"
        icon="tag"
        placeholder="Add a tag"
        aria-close-label="Delete this tag"
        @typing="getFilteredTags"
      >
        <template v-slot="props">
          {{ props.option[tagKey] }}
        </template>
      </b-taginput>
    </b-field>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
    },
    model: {
      required: true,
      type: Object,
    },
    modelKey: {
      required: true,
      type: String,
    },
    placeholder: {
      type: String,
    },
    size: {
      default: "is-normal",
    },
    tags: {
      required: true,
      type: Array,
    },
    tagKey: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      filteredTags: this.tags,
    };
  },
  computed: {
    computedTitle() {
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    },
  },
  methods: {
    clearValidationError() {
      this.$store.dispatch("clearValidationError", this.modelKey);
    },
    getFilteredTags(text) {
      this.filteredTags = this.tags.filter((option) => {
        return (
          option.naam.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
        );
      });
    },
  },
  watch: {
    tags: {
      deep: true,
      handler() {
        this.filteredTags = this.tags;
      },
    },
  },
  mounted() {
    this.filteredTags = this.tags;
  },
};
</script>
