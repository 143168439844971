<template>
  <div>
    <div class="title">Datadump {{ datadump.id }} aanpassen</div>
    <formInput
      class="customInput"
      :model="datadump"
      modelKey="naam"
      label="on-border"
      placeholder="Interne naam voor datadump"
    />

    <br />

    <button
      :class="{ 'is-loading': ajaxloading.edit_data_dump }"
      class="button is-primary is-fullwidth"
      @click="edit()"
    >
      Aanpassen
    </button>

    <br />
    <button
      :class="{ 'is-loading': ajaxloading.refresh_datadump }"
      class="button is-danger is-fullwidth"
      @click="cancelUpdate()"
    >
      Annuleren
    </button>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {},
      selectedFile: null,
    };
  },
  computed: {
    datadumps() {
      return this.$store.getters["uploads/datadumps"];
    },
    datadump() {
      if (this.datadumps.length) {
        return this.datadumps.find(
          (a) => a.id == this.$router.currentRoute.params.datadump
        );
      } else {
        return {};
      }
    },
  },
  methods: {
    edit() {
      this.$store.dispatch("uploads/editDatadump", this.datadump).then(() => {
        this.$router.go(-1);
      });
    },
    cancelUpdate() {
      this.$router.go(-1);
      this.$store.dispatch("uploads/refreshDatadump", this.datadump);
    },
  },
  mounted() {},
};
</script>
